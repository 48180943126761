import React, { useRef } from "react";
import PropTypes from "prop-types";

import { tabletLandscape } from "../../contants/breakpoints";
import useWidth from "../../utils/useWidth";

import "./Loader.css";

const Loader = ({ display }) => {
  const loader = useRef();
  const windowWidth = useWidth(loader);

  return (
    <div ref={loader} className="loader__wrapper">
      <div
        className="loader"
        style={{
          display: display,
          margin:
            display === "block" && windowWidth > tabletLandscape
              ? "10rem 0"
              : 0,
        }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  display: PropTypes.string,
};

Loader.defaultProps = {
  display: "none",
};

export default Loader;
