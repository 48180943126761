import React from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

import Loader from "../../components/loader/Loader";
import ProjectVideo from "../project-video/ProjectVideo";
import "./ProjectContent.css";

/**
 * Displays paragraphs, images and videos
 */
const ProjectContent = ({
  content,
  currentProjectSlug,
  desktop,
  loading,
  mediaLoaded,
  showMedia,
  showText,
  t,
}) => {
  return (
    <div className="project-content">
      {content &&
        content.map((el, key1) => {
          const key = Object.keys(el) && Object.keys(el)[0];

          if (key.includes("paragraph")) {
            const paragraphs = Object.values(el)[0];

            if (!paragraphs) return null;

            return (
              <p
                key={key1}
                className="project-content__paragraph"
                style={{ display: showText ? "block" : "none" }}
              >
                {paragraphs.map((_el, key2) => {
                  return (
                    currentProjectSlug &&
                    t(
                      `projects.${currentProjectSlug}.content.${key1}.paragraph.${key2}`,
                      {
                        returnObjects: true,
                      }
                    ) && (
                      <span key={key2}>
                        {t(
                          `projects.${currentProjectSlug}.content.${key1}.paragraph.${key2}`,
                          {
                            returnObjects: true,
                          }
                        )}
                      </span>
                    )
                  );
                })}
              </p>
            );
          } else if (key.includes("link")) {
            const link = Object.values(el)[0];

            if (!link) return null;

            return (
              <a
                key={key1}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="project-content__link"
                style={{ display: showText ? "block" : "none" }}
              >
                {link.text}
              </a>
            );
          } else if (key.includes("image")) {
            const image = Object.values(el)[0];

            if (!image) return null;

            return (
              <picture
                key={key1}
                className="project-content__media-wrapper"
                style={{
                  display: showMedia ? "flex" : "none",
                }}
              >
                {/* Normal image from tablet size */}
                <source
                  media="(min-width: 640px)"
                  srcSet={`/img/${image.title}`}
                />
                {/* Small image from mobile size */}
                <source
                  media="(min-width: 320px)"
                  srcSet={`/img/m-${image.title}`}
                />
                {/* Fallback */}
                <img
                  className={`project-content__media${
                    image.height === "long"
                      ? " project-content__media--long"
                      : ""
                  }`}
                  src={`/img/${image.title}`}
                  srcSet={`/img/m-${image.title}`}
                  style={{
                    display: !loading ? "block" : "none",
                  }}
                  alt="project visual"
                  onLoad={mediaLoaded}
                />
              </picture>
            );
          } else if (key.includes("video")) {
            const video = Object.values(el)[0];

            if (!video) return null;

            return (
              <ProjectVideo
                key={key1}
                desktop={desktop}
                loading={loading}
                mediaLoaded={mediaLoaded}
                height={video.height}
                showMedia={showMedia}
                title={video.title}
              />
            );
          }

          return null;
        })}

      {/* Display loader until heavy files have loaded */}
      <Loader display={loading && showMedia ? "block" : "none"} />
    </div>
  );
};

ProjectContent.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        height: PropTypes.string,
        title: PropTypes.string,
      }),
      paragraph: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  currentProjectSlug: PropTypes.string,
  desktop: PropTypes.bool,
  loading: PropTypes.bool,
  mediaLoaded: PropTypes.func,
  showMedia: PropTypes.bool,
  showText: PropTypes.bool,
};

ProjectContent.defaultProps = {
  content: [],
  currentProjectSlug: null,
  desktop: false,
  loading: false,
  mediaLoaded: null,
  showMedia: false,
  showText: false,
};

export default withNamespaces()(ProjectContent);
