import React from "react";
import { delaySmall } from "../../contants/animations";
import { home } from "../../contants/slugs";
import DelayLink from "../delay-link/DelayLink";

import "./GoBackButton.css";

const GoBackButton = () => (
  <button className="goback-btn goback-btn--safari">
    <DelayLink to={home} delay={delaySmall}>
      <svg
        className="goback-btn__icon"
        x="0px"
        y="0px"
        viewBox="0 0 49.1 45.4"
        space="preserve"
      >
        <g>
          <polyline
            className="goback-btn__icon-line"
            points="26,0.8 47.8,22.5 26,44.3"
          />
          <line
            className="goback-btn__icon-line"
            x1="0.9"
            y1="22.5"
            x2="47.9"
            y2="22.5"
          />
        </g>
      </svg>
    </DelayLink>
  </button>
);

export default GoBackButton;
