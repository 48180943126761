import React from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

import { setItemIntoLocalStorage } from "../../store/localStorage";

import DelayLink from "../delay-link/DelayLink";
import { home } from "../../contants/slugs";
import i18n from "../../i18n/i18n";
import LanguageSwitch from "../language-switch/LanguageSwitch";
import "./Header.css";
import { delaySmall } from "../../contants/animations";

const Header = ({ t }) => {
  const changeLanguage = (lng) => {
    // Change the language using i18n library
    i18n.changeLanguage(lng);
    // Store the new choice in Browser's localStorage
    setItemIntoLocalStorage("lng", lng);
  };

  return (
    <header className="header">
      {/* Title */}
      <DelayLink to={home} classes="header__title" delay={delaySmall}>
        Margot Gilmour
      </DelayLink>

      {/* Subtitle */}
      <div className="header__subtitle">
        <span className="header__subtitle-text">{t("header.title1")}</span>

        <span className="header__subtitle-text-separator">+</span>

        <span className="header__subtitle-text">{t("header.title2")}</span>
      </div>

      {/* Links */}
      <div className="header__links">
        <DelayLink to="/about" classes="header__link" delay={delaySmall}>
          {t("about.title")}
        </DelayLink>

        <span className="header__link-separator">|</span>

        <DelayLink to="/contact" classes="header__link" delay={delaySmall}>
          contact
        </DelayLink>

        <span className="header__link-separator">|</span>

        <DelayLink to="/instagram" classes="header__link" delay={delaySmall}>
          instagram
        </DelayLink>
      </div>

      <LanguageSwitch changeLanguage={changeLanguage}></LanguageSwitch>
    </header>
  );
};

Header.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(Header);
