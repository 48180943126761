import React from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

import ProjectBlackBlock from "../project-black-block/ProjectBlackBlock";
import ProjectContent from "../project-content/ProjectContent";
import ProjectCourtesy from "../project-courtesy/ProjectCourtesy";
import ProjectSubtitle from "../project-subtitle/ProjectSubtitle";

import "./ProjectRightColumn.css";
import { desktopSmall } from "../../contants/breakpoints";

const ProjectRightColumn = ({
  currentProjectSlug,
  loading,
  mediaLoaded,
  projectData,
  t,
  windowWidth,
}) => (
  <div className="project__block project-right-column">
    <div className="project-right-column__white-block">
      <h1 className="project__title">{projectData.title}</h1>

      {/* Subtitle */}
      {projectData.subtitle && (
        <ProjectSubtitle
          date={
            currentProjectSlug &&
            projectData.subtitle.date &&
            t(`projects.${currentProjectSlug}.subtitle.date`)
          }
          place={t(`projects.${currentProjectSlug}.subtitle.place`)}
          windowWidth={windowWidth}
        />
      )}

      {projectData && (
        <ProjectContent
          content={projectData.content}
          desktop={windowWidth >= desktopSmall}
          loading={loading}
          onload={mediaLoaded}
          showMedia
        />
      )}

      {!projectData["dark-content"] && <ProjectCourtesy />}
    </div>

    {projectData["dark-content"] && (
      <ProjectBlackBlock
        loading={loading}
        mediaLoaded={mediaLoaded}
        projectData={projectData}
        windowWidth={windowWidth}
        showMedia={true}
      />
    )}
  </div>
);

ProjectRightColumn.propTypes = {
  currentProjectSlug: PropTypes.string,
  loading: PropTypes.bool,
  mediaLoaded: PropTypes.func.isRequired,
  projectData: PropTypes.shape({
    content: PropTypes.array,
    "dark-content": PropTypes.array,
    subtitle: PropTypes.shape({
      date: PropTypes.string,
      place: PropTypes.string,
    }),
    title: PropTypes.string,
  }).isRequired,
  showMedia: PropTypes.bool,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number,
};

ProjectRightColumn.defaultProps = {
  currentProjectSlug: null,
  loading: false,
  projectData: {
    content: [],
    "dark-content": [],
    subtitle: null,
    title: null,
  },
  showMedia: false,
  windowWidth: 0,
};

export default withNamespaces()(ProjectRightColumn);
