import { useEffect, useState } from "react";

/**
 * Returns the size of an element everytime it resizes
 */
const useScroll = (ref) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      if (ref && ref.current && ref.current.offsetHeight) {
        const position =
          (window.pageYOffset / ref.current.offsetHeight).toFixed(2) * 100;
        setScrollPosition(position);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("resize", onScroll);
  }, [ref, scrollPosition]);

  return scrollPosition;
};

export default useScroll;
