export const black = "#000000";
export const blue_navy = "#0d006b";
export const blue_royal = "#0000ff";
export const blue_sky = "#b0f0ff";
export const brown_dark = "#42210b";
export const brown_light = "#c4b097";
export const brown_medium = "#6d2e13";
export const green_light = "#cff9d2";
export const green_lime = "#d9ff00";
export const green_olive = "#bbc221";
export const green_tree = "#13a562";
export const green_water = "#29d4ce";
export const grey = "#eae8e8";
export const grey_dark = "#bbb";
export const orange_brown = "#ff964d";
export const orange_pure = "#ff4c00";
export const pink_candy = "#ff718a";
export const pink_fushia = "#ff0079";
export const pink_skin = "#ffd7e9";
export const purple = "#8b42ff";
export const red_blood = "#cf1c24";
export const red_wine = "#950016";
export const yellow = "#fffda6";
export const yellow_gold = "#ae9930";
export const white = "#ffffff";
