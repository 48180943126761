import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import "./DelayLink.css";

const DelayLink = ({ children, delay, classes, to }) => {
  let history = useHistory();
  let delayTimeOut = null;

  useEffect(() => {
    return () => {
      clearTimeout(delayTimeOut);
    };
  }, [delayTimeOut]);

  const handleClick = () => {
    delayTimeOut = setTimeout(() => {
      history.push(to);
    }, delay);
  };

  return (
    <div className={`delay-link ${classes}`} onClick={handleClick}>
      {children}
    </div>
  );
};

DelayLink.prototype = {
  classes: PropTypes.string,
  delay: PropTypes.number,
  to: PropTypes.string.isRequired,
};

DelayLink.defaultProps = {
  classes: null,
  delay: 0,
};

export default DelayLink;
