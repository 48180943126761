import React from "react";
import PropTypes from "prop-types";

import {
  delayLarge,
  translationLeft,
  translationRight,
} from "../../contants/animations";

import DelayLink from "../delay-link/DelayLink";
import "./SwitchButton.css";

const SwitchButton = ({ axisPosition, left, link, switchPage }) => {
  return left ? (
    <button
      className="switch-btn switch-btn--left"
      onClick={() => switchPage(translationRight)}
      style={{ left: axisPosition }}
    >
      <DelayLink to={`/${link}`} delay={delayLarge}>
        <svg
          className="switch-btn__icon switch-btn__icon--left"
          x="0px"
          y="0px"
          viewBox="0 0 49.1 45.4"
          space="preserve"
        >
          <g>
            <polyline
              className="switch-btn__icon-line"
              points="26,0.8 47.8,22.5 26,44.3 	"
            />
            <line
              className="switch-btn__icon-line"
              x1="0.9"
              y1="22.5"
              x2="47.9"
              y2="22.5"
            />
          </g>
        </svg>
      </DelayLink>
    </button>
  ) : (
    <button
      className="switch-btn switch-btn--right"
      onClick={() => switchPage(translationLeft)}
      style={{ right: axisPosition }}
    >
      <DelayLink to={`/${link}`} delay={delayLarge}>
        <svg
          className="switch-btn__icon switch-btn__icon--right"
          x="0px"
          y="0px"
          viewBox="0 0 49.1 45.4"
          space="preserve"
        >
          <g>
            <polyline
              className="switch-btn__icon-line"
              points="26,0.8 47.8,22.5 26,44.3 	"
            />
            <line
              className="switch-btn__icon-line"
              x1="0.9"
              y1="22.5"
              x2="47.9"
              y2="22.5"
            />
          </g>
        </svg>
      </DelayLink>
    </button>
  );
};

SwitchButton.propTypes = {
  axisPosition: PropTypes.string,
  left: PropTypes.bool,
  link: PropTypes.string,
  switchPage: PropTypes.func.isRequired,
};

SwitchButton.defaultProps = {
  axisPosition: null,
  left: false,
  link: null,
};

export default SwitchButton;
