import * as actionTypes from "./actions";

const initialState = {
  // Useful to get it from the URL
  currentProjectSlug: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_CURRENT_PROJECT:
      return {
        ...state,
        currentProjectSlug: action.projectSlug,
      };
    default:
      return state;
  }
};

export default reducer;
