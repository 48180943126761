import React from "react";
import PropTypes from "prop-types";

import "./LanguageSwitch.css";

const LanguageSwitch = ({ changeLanguage }) => (
  <div className="language-switch">
    <button
      className="language-switch__btn"
      onClick={() => changeLanguage("en")}
    >
      en
    </button>
    /
    <button
      className="language-switch__btn"
      onClick={() => changeLanguage("fr")}
    >
      fr
    </button>
  </div>
);

LanguageSwitch.propTypes = {
  changeLanguage: PropTypes.func,
};

export default LanguageSwitch;
