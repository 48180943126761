import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { mobilePortraitLarge } from "../contants/breakpoints";

/**
 * Returns the size of an element everytime it resizes
 */
const useDrag = (elem, { leftSlide, rightSlide, windowWidth }) => {
  const history = useHistory();
  const [firstDragX, setFirstDragX] = useState();
  const [lastDragX, setLastDragX] = useState();
  const [translation, setTranslation] = useState(0);

  const onTouchEnd = useCallback(() => {
    if (
      Math.abs(firstDragX - lastDragX) > 125 &&
      Math.sign(firstDragX - lastDragX) === 1
    ) {
      setTimeout(() => {
        history.push(leftSlide);
      }, 200);
    } else if (
      Math.abs(firstDragX - lastDragX) > 125 &&
      Math.sign(firstDragX - lastDragX) === -1
    ) {
      setTimeout(() => {
        history.push(rightSlide);
      }, 200);
    }
  }, [firstDragX, history, lastDragX, leftSlide, rightSlide]);

  const onTouchMove = useCallback(
    (e) => {
      if (
        Math.abs(firstDragX - lastDragX) > 125 &&
        Math.sign(firstDragX - lastDragX) === 1
      ) {
        if (windowWidth >= mobilePortraitLarge) {
          setTranslation(-5);
        } else {
          setTranslation(-10);
        }
      } else if (
        Math.abs(firstDragX - lastDragX) > 125 &&
        Math.sign(firstDragX - lastDragX) === -1
      ) {
        if (windowWidth >= mobilePortraitLarge) {
          setTranslation(5);
        } else {
          setTranslation(10);
        }
      }

      setLastDragX(Math.round(e.touches[0].clientX));
    },
    [firstDragX, lastDragX, windowWidth]
  );

  const onTouchStart = (e) => {
    setFirstDragX(Math.round(e.touches[0].clientX));
  };

  useEffect(() => {
    if (!elem) return;

    elem.addEventListener("touchstart", onTouchStart);
    elem.addEventListener("touchmove", onTouchMove);
    elem.addEventListener("touchend", onTouchEnd);

    return () => {
      window.removeEventListener("touchend", onTouchEnd);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchstart", onTouchStart);
    };
  }, [elem, firstDragX, history, lastDragX, onTouchEnd, onTouchMove]);

  return translation;
};

export default useDrag;
