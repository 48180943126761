import React from "react";
import PropTypes from "prop-types";

import { mobileLandscape } from "../../contants/breakpoints";

import "./ProjectSubtitle.css";

const ProjectSubtitle = ({ date, place, windowWidth }) => {
  return (
    <h2 className="project-subtitle">
      <span>{place}</span>

      {date && (
        <span>{windowWidth >= mobileLandscape ? ` - ${date}` : `${date}`}</span>
      )}
    </h2>
  );
};

ProjectSubtitle.propTypes = {
  date: PropTypes.string,
  place: PropTypes.string,
  windowWidth: PropTypes.number,
};

ProjectSubtitle.defaultProps = {
  date: null,
  place: null,
  windowWidth: 0,
};

export default ProjectSubtitle;
