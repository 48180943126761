import React, { useEffect, useState } from "react";

import i18n from "../../i18n/i18n";
import { getItemFromLocalStorage } from "../../store/localStorage";

import Loader from "../../components/loader/Loader";

// import ResumeContentBlock from "../../components/resume/ResumeContentBlock";
// import resumeData from "./resumeData.json";

import "./Resume.css";

const Resume = () => {
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [showResume, setShowResume] = useState(false);

  useEffect(() => {
    setCurrentLanguage(i18n.language || getItemFromLocalStorage("lng") || "en");

    i18n.on("languageChanged", () => {
      setCurrentLanguage(
        i18n.language || getItemFromLocalStorage("lng") || "en"
      );
    });
  }, []);

  return (
    <div className="resume">
      <Loader display={showResume ? "none" : "block"} />

      <picture>
        {/* Normal image from tablet size */}
        <source
          media="(min-width: 640px)"
          srcSet={`/img/resume_${currentLanguage}.png`}
        />
        {/* Small image from mobile size */}
        <source
          media="(min-width: 320px)"
          srcSet={`/img/m-resume_${currentLanguage}.png`}
        />
        {/* Fallback */}
        <img
          className="resume__img"
          src={`/img/resume_${currentLanguage}.png`}
          srcSet={`/img/m-resume_${currentLanguage}.png`}
          alt={`My curriculum vitae in ${currentLanguage}`}
          onLoad={() => setShowResume(true)}
        />
      </picture>

      {/* {resumeData &&
        resumeData.data &&
        Object.entries(resumeData.data).map((blocks, index) => {
          // First Part
          if (blocks[0] === "row") {
            const block = blocks[1];
            return block.map((row) => (
              <div
                key={row.partTitle}
                className="resume__block resume__block--column"
              >
                <div className=" resume__title resume__title--part resume__title--right">
                  {row.partTitle}
                </div>

                {row.content.map((ct, idx) => (
                  <ResumeContentBlock
                    key={idx}
                    info={ct.info}
                    text={ct.description}
                    title={ct.title}
                  />
                ))}
              </div>
            ));
            // Second part
          } else {
            const block = blocks[1];
            return (
              <div className="resume__block resume__block--wrap" key={index}>
                {block.map((column, idx) => (
                  <ResumeContentBlock
                    key={idx}
                    title={column.partTitle}
                    left={idx % 2 === 0}
                    right={idx % 2 !== 0}
                  >
                    {column.content.map((paragraphs, id) => (
                      <div
                        key={id}
                        className={`resume__text${
                          idx % 2 === 0 ? " resume__text--right" : ""
                        }${id === 0 ? " resume__text--bottom" : ""}`}
                      >
                        {paragraphs.paragraph.map((el, i) => (
                          <div key={i}>{el}</div>
                        ))}
                      </div>
                    ))}
                  </ResumeContentBlock>
                ))}
              </div>
            );
          }
        })} */}
    </div>
  );
};

export default Resume;
