/**
 * Debounce is higher for Safari to avoid jerky effect
 */
export const computeDebounceValue = () => {
  let debounceValue = 0;
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("safari") !== -1) {
    if (userAgent.indexOf("chrome") > -1) {
      debounceValue = 10;
    } else {
      debounceValue = 34;
    }
  }

  return debounceValue;
};

/**
 * a = (Yb - Ya) / (Xa - Xb)
 * b = y - a * X
 */
export const computePositionCoef = (innerHeight) => {
  if (innerHeight > 1016) {
    return (0.00018 * innerHeight + 0.4742).toFixed(2);
  } else if (innerHeight > 972) {
    return (0.0003 * innerHeight + 0.3372).toFixed(2);
  } else if (innerHeight > 904) {
    return (0.00042 * innerHeight + 0.22176).toFixed(2);
  } else if (innerHeight > 838) {
    return (0.00045 * innerHeight + 0.1932).toFixed(2);
  } else if (innerHeight > 659) {
    return (0.00067 * innerHeight + 0.00854).toFixed(2);
  } else if (innerHeight > 508) {
    return (0.00106 * innerHeight - 0.24854).toFixed(2);
  }
  return 0;
};
