/**
 * Compute the position of the arrows around the central block
 * For Account, Contact, Instgram and Home pages
 */
export const computeSwitchButtonPosition = (windowWidth) => {
  if (windowWidth >= 1400) {
    return "13rem";
  } else {
    return "calc((100vw - 84rem) / 4)"; // 42 * 2
  }
};
