import React, { useEffect, useRef, useState } from "react";
import smoothscroll from "smoothscroll-polyfill";
import PropTypes from "prop-types";

import { about, instagram } from "../../contants/slugs";
import { mobileLandscape, tabletLandscape } from "../../contants/breakpoints";
import useDrag from "../../utils/useDrag";
import useScroll from "../../utils/useScroll";
import useWidth from "../../utils/useWidth";

import ProjectsMosaic from "../../components/projects-mosaic/ProjectsMosaic";
import SwitchButton from "../../components/switch-button/SwitchButton";
import "./Home.css";

const Home = ({ showSwipeInfo }) => {
  const elem = document.getElementById("home");

  const block = useRef();
  const [showGoUpBtn, setShowGoUpBtn] = useState(false);
  const [translationX, setTranslationX] = useState(0);
  // Watcher on window width to compute switch-buttons position
  const scrollPosition = useScroll(block);
  const windowWidth = useWidth(block);
  // Swipe effect on mobile and tablet
  const translation = useDrag(elem, {
    leftSlide: instagram,
    rightSlide: about,
    windowWidth,
  });

  useEffect(() => {
    // Polyfill for Safari
    smoothscroll.polyfill();

    if (windowWidth < mobileLandscape && scrollPosition > 15) {
      setShowGoUpBtn(true);
    } else {
      setShowGoUpBtn(false);
    }

    setTranslationX(translation);
  }, [scrollPosition, translation, windowWidth]);

  return (
    <div ref={block} id="home" className="home">
      {showSwipeInfo && <p className="home_swipe-info">swipe me</p>}

      <button
        className="home_goup-btn"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        style={{
          display: showGoUpBtn ? "block" : "none",
        }}
      >
        <svg
          className="home_goup-btn__icon"
          x="0px"
          y="0px"
          viewBox="0 0 49.1 45.4"
          space="preserve"
        >
          <g>
            <polyline
              className="home_goup-btn__icon-line"
              points="26,0.8 47.8,22.5 26,44.3 	"
            />
            <line
              className="home_goup-btn__icon-line"
              x1="0.9"
              y1="22.5"
              x2="47.9"
              y2="22.5"
            />
          </g>
        </svg>
      </button>

      <SwitchButton
        axisPosition="calc(12rem / 2)"
        left
        link={instagram}
        switchPage={(translate) => setTranslationX(translate)}
      />

      <ProjectsMosaic
        responsive
        showSwipeInfo={windowWidth < tabletLandscape && showSwipeInfo}
        title
        translationX={translationX}
        windowWidth={windowWidth}
      />

      <SwitchButton
        axisPosition="calc(12rem / 2)"
        link={about}
        switchPage={(translate) => setTranslationX(translate)}
      />
    </div>
  );
};

Home.propTypes = {
  showSwipeInfo: PropTypes.bool,
};

Home.defaultProps = {
  showSwipeInfo: false,
};

export default Home;
