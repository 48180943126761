import React, { useState } from "react";
import PropTypes from "prop-types";

import "./ProjectVideo.css";

const ProjectVideo = ({
  desktop,
  height,
  loading,
  mediaLoaded,
  showMedia,
  title,
}) => {
  const [poster, showPoster] = useState(true);

  // Show video only if image-poster has been clicked
  return poster ? (
    <picture
      className="project-content__media-wrapper"
      style={{
        display: showMedia ? "flex" : "none",
      }}
    >
      {/* Normal image from tablet size */}
      <source media="(min-width: 640px)" srcSet={`/img/${title}.jpg`} />
      {/* Small image from mobile size */}
      <source media="(min-width: 320px)" srcSet={`/img/m-${title}.jpg`} />
      {/* Fallback */}
      <img
        className={`project-content__media project-video${
          height === "long" ? " project-content__media--long" : ""
        }`}
        src={`/img/${title}.jpg`}
        srcSet={`/img/m-${title}.jpg`}
        style={{
          display: !loading ? "block" : "none",
        }}
        alt="project visual"
        onClick={() => {
          showPoster(false);
        }}
        onLoad={mediaLoaded}
      />
    </picture>
  ) : (
    <video
      className={`project-content__media project-video${
        height === "long" ? " project-content__media--long" : ""
      }`}
      autoPlay
      controls
    >
      <source
        media="all and (min-width: 640px)"
        src={`/videos/${title}`}
        type="video/mp4"
      />
      <source
        media="all and (min-width: 320px)"
        src={`/videos/${desktop ? title : "m-" + title}`}
        type="video/mp4"
      />
    </video>
  );
};

ProjectVideo.propTypes = {
  desktop: PropTypes.bool,
  height: PropTypes.string,
  loading: PropTypes.bool,
  mediaLoaded: PropTypes.func,
  showMedia: PropTypes.bool,
  title: PropTypes.string,
};

ProjectVideo.defaultProps = {
  desktop: false,
  height: null,
  loading: false,
  mediaLoaded: null,
  showMedia: false,
  title: null,
};

export default ProjectVideo;
