import React from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

import { home } from "../../contants/slugs";
import { desktopSmall } from "../../contants/breakpoints";
import { delaySmall } from "../../contants/animations";

import DelayLink from "../delay-link/DelayLink";
import GoBackButton from "../go-back-button/GoBackButton";
import ProjectContent from "../project-content/ProjectContent";
import ProjectCourtesy from "../project-courtesy/ProjectCourtesy";
import ProjectSubtitle from "../project-subtitle/ProjectSubtitle";
import ProjectsMosaic from "../projects-mosaic/ProjectsMosaic";

import "./ProjectLeftColumn.css";

const ProjectLeftColumn = ({
  currentProjectSlug,
  loading,
  mediaLoaded,
  mosaicRelativePosition,
  projectData,
  showMedia,
  showMosaic,
  t,
  windowWidth,
}) => (
  <div className="project__block project-left-column">
    <div className="project-left-column__content">
      {/* Go back button */}
      <GoBackButton />

      {/* Mosaic */}
      {(windowWidth < desktopSmall ||
        (windowWidth >= desktopSmall && !loading && showMosaic)) && (
        <ProjectsMosaic
          translationY={mosaicRelativePosition}
          windowWidth={windowWidth}
        />
      )}

      {/* Logo */}
      <DelayLink to={home} classes="project__logo" delay={delaySmall}>
        Margot Gilmour
      </DelayLink>

      {/* Description */}
      <div className="project-left-column__description">
        {currentProjectSlug &&
          projectData.description &&
          projectData.description.map((_el, key) => (
            <span key={key}>
              {t(`projects.${currentProjectSlug}.description.${key}`, {
                returnObjects: true,
              })}
            </span>
          ))}
      </div>

      <div className="project-left-column__title-wrapper">
        {/* Title */}
        <h1 className="project__title">{projectData.title}</h1>

        {/* Subtitle */}
        {currentProjectSlug && projectData.subtitle && (
          <ProjectSubtitle
            date={
              projectData.subtitle.date &&
              t(`projects.${currentProjectSlug}.subtitle.date`)
            }
            place={t(`projects.${currentProjectSlug}.subtitle.place`)}
            windowWidth={windowWidth}
          />
        )}
      </div>

      {/* Info */}
      {currentProjectSlug && projectData.info && (
        <h3 className="project-left-column__info">
          {projectData.info &&
            projectData.info.map((_el, key) => (
              <span key={key}>
                {t(`projects.${currentProjectSlug}.info.${key}`, {
                  returnObjects: true,
                })}
              </span>
            ))}
        </h3>
      )}

      {/* Content (paragraphs, media) */}
      {projectData && (
        <ProjectContent
          content={projectData.content}
          currentProjectSlug={currentProjectSlug}
          desktop={windowWidth >= desktopSmall}
          loading={loading}
          mediaLoaded={mediaLoaded}
          showMedia={showMedia}
          showText
        />
      )}

      {/* Courtesy */}
      {windowWidth < desktopSmall && !projectData["dark-content"] && (
        <ProjectCourtesy />
      )}
    </div>
  </div>
);

ProjectLeftColumn.propTypes = {
  currentProjectSlug: PropTypes.string,
  loading: PropTypes.bool,
  mediaLoaded: PropTypes.func,
  mosaicRelativePosition: PropTypes.number,
  projectData: PropTypes.shape({
    content: PropTypes.array,
    "dark-content": PropTypes.array,
    description: PropTypes.arrayOf(PropTypes.string),
    info: PropTypes.arrayOf(PropTypes.string),
    subtitle: PropTypes.shape({
      date: PropTypes.string,
      place: PropTypes.string,
    }),
    title: PropTypes.string,
  }).isRequired,
  showMedia: PropTypes.bool,
  showMosaic: PropTypes.bool,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number,
};

ProjectLeftColumn.defaultProps = {
  currentProjectSlug: null,
  loading: false,
  mosaicRelativePosition: 0,
  projectData: {
    content: [],
    "dark-content": [],
    description: [],
    info: [],
    subtitle: null,
    title: null,
  },
  showMedia: false,
  showMosaic: false,
  windowWidth: 0,
};

export default withNamespaces()(ProjectLeftColumn);
