import React from "react";
import PropTypes from "prop-types";

import { black, white } from "../../contants/colors";
import { desktopSmall } from "../../contants/breakpoints";

import ProjectContent from "../project-content/ProjectContent";
import ProjectCourtesy from "../project-courtesy/ProjectCourtesy";
import "./ProjectBlackBlock.css";

/**
 * Displays images and videos with above a black background
 */
const ProjectBlackBlock = ({
  loading,
  mediaLoaded,
  projectData,
  showMedia,
  windowWidth,
}) => (
  <div
    className="project-black-block"
    style={{ backgroundColor: loading ? white : black }}
  >
    <ProjectContent
      content={projectData["dark-content"]}
      desktop={windowWidth >= desktopSmall}
      loading={loading}
      mediaLoaded={mediaLoaded}
      showMedia={showMedia}
    />

    <ProjectCourtesy />
  </div>
);

ProjectBlackBlock.propTypes = {
  loading: PropTypes.bool,
  mediaLoaded: PropTypes.func.isRequired,
  projectData: PropTypes.shape({
    "dark-content": PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          height: PropTypes.string,
          title: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  showMedia: PropTypes.bool,
  windowWidth: PropTypes.number,
};

ProjectBlackBlock.defaultProps = {
  loading: false,
  projectData: {
    "dark-content": [],
  },
  showMedia: false,
  windowWidth: 0,
};

export default ProjectBlackBlock;
