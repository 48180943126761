import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

import { brown_dark, pink_fushia } from "../../contants/colors";
import { about, instagram } from "../../contants/slugs";
import { computeSwitchButtonPosition } from "../../components/box-link/utils";
import useDrag from "../../utils/useDrag";
import useWidth from "../../utils/useWidth";

import BoxLink from "../../components/box-link/BoxLink";
import SwitchButton from "../../components/switch-button/SwitchButton";
import "./Contact.css";

const colors = {
  color1: pink_fushia,
  color2: brown_dark,
};

const Contact = ({ t }) => {
  const elem = document.getElementById("contact");

  const block = useRef();
  const [translationX, setTranslationX] = useState(0);
  // Watcher on window width to compute switch-buttons position
  const windowWidth = useWidth(block);
  // Swipe effect on mobile and tablet
  const translation = useDrag(elem, {
    leftSlide: about,
    rightSlide: instagram,
    windowWidth,
  });

  useEffect(() => {
    setTranslationX(translation);
  }, [translation]);

  return (
    <div ref={block} id="contact" className="contact">
      <SwitchButton
        axisPosition={computeSwitchButtonPosition(windowWidth)}
        left
        link={about}
        switchPage={(translate) => setTranslationX(translate)}
      />

      <BoxLink
        colors={colors}
        title={t("contact.title")}
        translationX={translationX}
        windowWidth={windowWidth}
      >
        <a
          className="box-link__link contact__email"
          href="mailto: tmargot014@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          tmargot014@gmail.com
        </a>

        <p className="contact__paragraph">
          {t("contact.based")}
          {", "}
          <a
            className="contact__link"
            href="https://www.linkedin.com/in/margot-traor%C3%A9-a2801a13a/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </a>
        </p>

        <p className="contact__paragraph">
          {t("contact.development")}
          {", "}
          <a
            className="contact__link"
            href="https://www.instagram.com/eselalba.studio/"
            target="_blank"
            rel="noopener noreferrer"
          >
            eselalba
          </a>
        </p>
      </BoxLink>

      <SwitchButton
        axisPosition={computeSwitchButtonPosition(windowWidth)}
        link={instagram}
        switchPage={(translate) => setTranslationX(translate)}
      />
    </div>
  );
};

Contact.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(Contact);
