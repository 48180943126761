import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  getItemFromLocalStorage,
  setItemIntoLocalStorage,
} from "../../store/localStorage";
import { storeCurrentProject } from "../../store/actions";

import { computeDebounceValue, computePositionCoef } from "./utils";
import { desktopSmall } from "../../contants/breakpoints";
import debounce from "../../utils/debounce";
import { project } from "../../contants/slugs";
import i18n from "../../i18n/i18n";
import useWidth from "../../utils/useWidth";
import siteData from "../../i18n/locales/fr/translation.json";

import LanguageSwitch from "../../components/language-switch/LanguageSwitch";
import ProjectBlackBlock from "../../components/project-black-block/ProjectBlackBlock";
import ProjectRightColumn from "../../components/project-right-column/ProjectRightColumn";
import ProjectLeftColumn from "../../components/project-left-column/ProjectLeftColumn";
import "./Project.css";

const Project = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const counter = useRef(0);
  const projectPage = useRef();

  const currentProjectSlug = useSelector((state) => state.currentProjectSlug);

  // Display loader until heavy media files have loaded
  const [loading, setLoading] = useState(true);
  const [maxScroll, setMaxScroll] = useState(0);
  // Position relative to device's height
  const [mosaicRelativePosition, setMosaicRelativePosition] = useState();
  const [nbMedia, setNbMedia] = useState(0);
  const [projectData, setProjectData] = useState(null);
  const [showMosaic, setShowMosaic] = useState(true);

  // Watcher on window width to compute switch-buttons position
  const windowWidth = useWidth(projectPage);

  const changeLanguage = (lng) => {
    // Change the language using i18n library
    i18n.changeLanguage(lng);
    // Store the new choice in Browser's localStorage
    setItemIntoLocalStorage("lng", lng);
  };

  /**
   * Compute maximum value of scroll in page
   * Necessary to compute mosaic position
   */
  const computeMaxScrollValue = useCallback(() => {
    const page = document.getElementById("projectPage");
    if (page?.scrollHeight && window?.innerHeight) {
      setMaxScroll(page.scrollHeight - window.innerHeight);
    }
  }, []);

  /**
   * Compute mosaic position
   */
  const computeMosaicPosition = useCallback(() => {
    if (maxScroll === 0) return;
    const coef = computePositionCoef(window.innerHeight);
    const scrollPositionCompute = Math.round(
      coef * (window.scrollY / maxScroll) * 100
    );
    if (scrollPositionCompute === 0) {
      // Trick to force state update when position reaches 0
      setMosaicRelativePosition(0.0001);
    }
    setMosaicRelativePosition(scrollPositionCompute);
  }, [maxScroll]);

  /**
   * Display loader until heavy media files have loaded
   */
  const mediaLoaded = () => {
    counter.current += 1;
    if (counter.current >= nbMedia) {
      setLoading(false);
    }
  };

  /**
   * useEffect 1
   * On component init only
   */
  useEffect(() => {
    /**
     * -- UGLY TRICK ---
     * Reload the page when we switch of project
     * This is to force useCallback to forget previous maxScroll
     * to avoid mosaic's jerky movement
     */
    const pathname = location.pathname.split("/project/")[1];
    const currentPage = getItemFromLocalStorage("page");
    if (windowWidth >= desktopSmall && currentPage !== pathname) {
      setShowMosaic(false);
      setItemIntoLocalStorage("page", pathname);
      window.location.reload();
      setShowMosaic(true);
    } else {
      setItemIntoLocalStorage("page", pathname);
    }

    // Set project id if the page has been reloaded
    if (!currentProjectSlug) {
      const projectSlug = window.location.href.split(project + "/")[1];
      if (projectSlug) dispatch(storeCurrentProject(projectSlug));
    }

    // Find and set project data
    const projectContent = siteData.projects[currentProjectSlug];
    if (projectContent && Object.values(projectContent).length) {
      setProjectData(projectContent);
      setNbMedia(projectContent["media"]);
    }

    /**
     * Scroll to the top of the page on reload
     * Only solution for Chrome
     */
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    /**
     * Set maximum scroll to compute mosaic absolute position
     * Wait 500 ms for the DOM to be loaded before capturing the element
     */
    setTimeout(() => {
      if (!loading) {
        const page = document.getElementById("projectPage");
        setMaxScroll(page.scrollHeight - window.innerHeight);
      }
    }, 500);
  }, [
    currentProjectSlug,
    dispatch,
    loading,
    location.pathname,
    nbMedia,
    windowWidth,
  ]);

  /**
   * useEffect 2
   * On component change
   */
  useEffect(() => {
    // Debounce is higher for Safari to avoid jerky effect
    const debounceValue = computeDebounceValue();

    /**
     * Watch for scrolling to move mosaic (desktop only)
     * Enabled after the maxScroll value has been set
     */
    if (windowWidth < desktopSmall) {
      window.removeEventListener("scroll", computeMosaicPosition);
      window.removeEventListener("resize", computeMaxScrollValue);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (maxScroll !== 0) {
      setTimeout(() => {
        window.addEventListener(
          "scroll",
          debounce(computeMosaicPosition, debounceValue)
        );
        window.addEventListener("resize", debounce(computeMaxScrollValue, 100));
      }, 600);
    }

    return () => {
      window.removeEventListener("scroll", computeMosaicPosition);
      window.removeEventListener("resize", computeMaxScrollValue);
    };
  }, [computeMaxScrollValue, computeMosaicPosition, maxScroll, windowWidth]);

  if (!projectData) {
    return (
      <div ref={projectPage} className="project">
        <div className="project__not-found">
          Project not found, return to{" "}
          <Link className="project__not-found-link" to="/">
            home page
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={projectPage}
      key={currentProjectSlug}
      id="projectPage"
      className="project"
      style={{
        height: !loading && maxScroll !== 0 ? "auto" : "100%",
        overflowY: !loading && maxScroll !== 0 ? "scroll" : "hidden",
      }}
    >
      <LanguageSwitch changeLanguage={changeLanguage}></LanguageSwitch>

      {/* Left column - white block - Mobile & tablet only */}
      <ProjectLeftColumn
        currentProjectSlug={currentProjectSlug}
        loading={loading}
        mediaLoaded={() => mediaLoaded()}
        mosaicRelativePosition={mosaicRelativePosition}
        projectData={projectData}
        showMosaic={showMosaic}
        windowWidth={windowWidth}
        showMedia={windowWidth < desktopSmall}
        showText={true}
      />

      {/* Left column - black block - Mobile & tablet only */}
      {projectData["dark-content"] && windowWidth < desktopSmall && (
        <ProjectBlackBlock
          loading={loading}
          mediaLoaded={() => mediaLoaded()}
          projectData={projectData}
          windowWidth={windowWidth}
          showMedia={windowWidth < desktopSmall}
        />
      )}

      {/* Right column - Desktop only */}
      <ProjectRightColumn
        currentProjectSlug={currentProjectSlug}
        loading={loading}
        mediaLoaded={() => mediaLoaded()}
        projectData={projectData}
        windowWidth={windowWidth}
        showMedia
      />
    </div>
  );
};

export default Project;
