import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

import { brown_light, yellow_gold } from "../../contants/colors";
import { contact, projects } from "../../contants/slugs";
import { computeSwitchButtonPosition } from "../../components/box-link/utils";
import useDrag from "../../utils/useDrag";
import useWidth from "../../utils/useWidth";

import BoxLink from "../../components/box-link/BoxLink";
import SwitchButton from "../../components/switch-button/SwitchButton";
import "./Instagram.css";

const colors = {
  color1: yellow_gold,
  color2: brown_light,
};

const Instagram = ({ t }) => {
  const elem = document.getElementById("instagram");

  const block = useRef();
  const [translationX, setTranslationX] = useState(0);
  // Watcher on window width to compute switch-buttons position
  const windowWidth = useWidth(block);
  // Swipe effect on mobile and tablet
  const translation = useDrag(elem, {
    leftSlide: contact,
    rightSlide: projects,
    windowWidth,
  });

  useEffect(() => {
    setTranslationX(translation);
  }, [translation]);

  return (
    <div ref={block} id="instagram" className="instagram">
      <SwitchButton
        axisPosition={computeSwitchButtonPosition(windowWidth)}
        left
        link={contact}
        switchPage={(translate) => setTranslationX(translate)}
      />

      <BoxLink
        colors={colors}
        title="Instagram"
        translationX={translationX}
        windowWidth={windowWidth}
      >
        <a
          className="instagram__link"
          href="https://www.instagram.com/margotgilmour/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("instagram.link")}
        </a>

        <a
          className="instagram__link"
          href="https://www.instagram.com/somegoodtracks/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Somegoodtracks
        </a>
      </BoxLink>

      <SwitchButton
        axisPosition={computeSwitchButtonPosition(windowWidth)}
        link={projects}
        switchPage={(translate) => setTranslationX(translate)}
      />
    </div>
  );
};

Instagram.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(Instagram);
