import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

import { black, purple } from "../../contants/colors";
import { contact, projects } from "../../contants/slugs";
import { computeSwitchButtonPosition } from "../../components/box-link/utils";
import useDrag from "../../utils/useDrag";
import useWidth from "../../utils/useWidth";

import BoxLink from "../../components/box-link/BoxLink";
import SwitchButton from "../../components/switch-button/SwitchButton";
import "./About.css";

const colors = {
  color1: black,
  color2: purple,
};

const About = ({ t }) => {
  const elem = document.getElementById("about");

  const block = useRef();
  const [translationX, setTranslationX] = useState(0);
  // Watcher on window width to compute switch-buttons position
  const windowWidth = useWidth(block);
  // Swipe effect on mobile and tablet
  const translation = useDrag(elem, {
    leftSlide: projects,
    rightSlide: contact,
    windowWidth,
  });

  useEffect(() => {
    setTranslationX(translation);
  }, [translation]);

  return (
    <div ref={block} id="about" className="about">
      <SwitchButton
        axisPosition={computeSwitchButtonPosition(windowWidth)}
        left
        link={projects}
        switchPage={(translate) => setTranslationX(translate)}
      />

      <BoxLink
        colors={colors}
        title={`${t("about.title")}`}
        translationX={translationX}
        windowWidth={windowWidth}
      >
        <p>{t("about.content")}</p>

        <Link to="/resume" className="about__text-link box-link__link">
          {t("about.link")}
        </Link>
      </BoxLink>

      <SwitchButton
        axisPosition={computeSwitchButtonPosition(windowWidth)}
        link={contact}
        switchPage={(translate) => setTranslationX(translate)}
      />
    </div>
  );
};

About.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(About);
