import React from "react";

import "./ProjectCourtesy.css";

const ProjectCourtesy = () => (
  <p className="project-courtesy">
    Droits réservés - Toute reproduction des œuvres interdites sauf autorisation
    expresse de l’auteure. Toute reproduction nécessite l’autorisation expresse
    de l’auteure. Toute exploitation non autorisée constitue un acte de
    contrefaçon au sens des dispositions des articles L.122-4 et L335-2 du Code
    de la Propriété Intellectuelle.
  </p>
);

export default ProjectCourtesy;
