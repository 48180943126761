import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Instagram from "./pages/instagram/Instagram";
import Project from "./pages/project/Project";
import Resume from "./pages/resume/Resume";
import "./App.css";

function App() {
  const [showSwipeInfo, setShowSwipeInfo] = useState(true);

  /**
   * After home page has loaded
   * Stop mosaic animation to inform about swipe effect
   */
  useEffect(() => {
    if (showSwipeInfo) {
      setTimeout(() => {
        setShowSwipeInfo(false);
      }, 4000);
    }

    // document.oncontextmenu = function (e) {
    //   if (e.button === 2) {
    //     e.preventDefault();
    //     return false;
    //   }
    // };
  }, [showSwipeInfo]);

  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/about">
            <Header />
            <About />
          </Route>

          <Route path="/contact">
            <Header />
            <Contact />
          </Route>

          <Route path="/instagram">
            <Header />
            <Instagram />
          </Route>

          <Route path="/project/:id">
            <Project />
          </Route>

          <Route path="/resume">
            <Header />
            <Resume />
          </Route>

          <Route path="/">
            <Header />
            <Home showSwipeInfo={showSwipeInfo} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
