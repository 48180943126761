import { useEffect, useState } from "react";

/**
 * Returns the size of an element everytime it resizes
 */
const useWidth = (ref) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!width) {
      setWidth(ref.current.offsetWidth);
    }

    const onResize = () => {
      if (ref && ref.current && ref.current.offsetWidth) {
        setWidth(ref.current.offsetWidth);
      }
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [ref, width]);

  return width;
};

export default useWidth;
