import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import { getItemFromLocalStorage } from "../store/localStorage";
import translationEn from "./locales/en/translation.json";
import translationFr from "./locales/fr/translation.json";

const resources = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
};

i18n
  .use(detector)
  .use(reactI18nextModule) // Passes i18n down to react-i18next
  .init({
    interpolation: {
      escapeValue: false, // React already safe from XSS
    },
    lng: getItemFromLocalStorage("lng") || "fr",
    fallbackLng: "en", // Use en if detected lng is not available
    resources,
  });

export default i18n;
