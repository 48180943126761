import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { storeCurrentProject } from "../../store/actions";

import { animL, animM } from "../../contants/animations";
import {
  desktopLarge,
  desktopSmall,
  tabletLandscape,
} from "../../contants/breakpoints";
import projectsDesktop from "../../contants/projects-mosaic-desktop.json";
import projectsMobile from "../../contants/projects-mosaic-mobile.json";
import "./ProjectsMosaic.css";

const ProjectsMosaic = ({
  responsive,
  showSwipeInfo,
  translationX,
  translationY,
  windowWidth,
}) => {
  const dispatch = useDispatch();
  const currentProjectSlug = useSelector((state) => state.currentProjectSlug);
  // Display the correct mosaic depending on the window's width
  const [projectsData, setProjectsData] = useState(projectsDesktop);

  // Animation duration is higher for Safari to avoid jerky effect
  const computeAnimationDuration = () => {
    let animationDuration = animM;
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("safari") !== -1) {
      if (userAgent.indexOf("chrome") > -1) {
        animationDuration = animM;
      } else {
        animationDuration = animL;
      }
    }

    return animationDuration;
  };

  const isHomePage = useCallback(() => translationX !== undefined, [
    translationX,
  ]);
  const isProjectPage = () => translationY !== undefined;

  /**
   * Show the correct suite of colorful rectangles depending on window-width
   * Home page only
   */
  useEffect(() => {
    if (!isHomePage()) return;

    if (windowWidth < desktopLarge) {
      setProjectsData(projectsMobile);
    } else {
      setProjectsData(projectsDesktop);
    }
  }, [isHomePage, windowWidth]);

  return (
    <div
      className="projects-mosaic safari-only"
      style={{
        animation:
          (isHomePage() && showSwipeInfo && "showswipeinfo 3s 1s") || // Mobile only
          (isHomePage() &&
            windowWidth >= tabletLandscape &&
            translationX !== 0 &&
            "hidehomeblock 1.5s"), // Desktop only
        transform:
          (isHomePage() && `translateX(${translationX}%)`) ||
          (isProjectPage() &&
            windowWidth >= desktopSmall &&
            `rotate(90deg) translate(${translationY}vh, 0%)`),
        transition:
          isProjectPage() && `transform ${computeAnimationDuration()}`,
      }}
    >
      {projectsData?.data?.map((project) => {
        return project.slug ? (
          // Link to project's page if there's a project behind
          <Link
            key={project.id}
            to={`/project/${project.slug}`}
            onClick={() => dispatch(storeCurrentProject(project.slug))}
            className={`projects-mosaic__box${
              responsive ? " projects-mosaic__box--responsive" : ""
            }`}
            title={project.title}
            style={{
              backgroundColor: project.color,
            }}
          >
            {/* Home page - show title when there's one */}
            {isHomePage() && project.title}

            {/* Project page - show colorful box only */}
            {isProjectPage() && project.slug === currentProjectSlug && (
              <div className="projects-mosaic__box-selection"></div>
            )}
          </Link>
        ) : (
          // Simple div if there's no project behind
          <div
            key={project.id}
            className={`projects-mosaic__box${
              responsive ? " projects-mosaic__box--responsive" : ""
            }`}
            style={{
              backgroundColor: project.color,
            }}
          ></div>
        );
      })}
    </div>
  );
};

ProjectsMosaic.propTypes = {
  responsive: PropTypes.bool,
  showSwipeInfo: PropTypes.bool,
  translationX: PropTypes.number,
  translationY: PropTypes.number,
  windowWidth: PropTypes.number,
};

ProjectsMosaic.defaultProps = {
  responsive: false,
  showSwipeInfo: false,
  translationX: undefined,
  translationY: undefined,
  windowWidth: 0,
};

export default ProjectsMosaic;
