/**
 * Set a key/value pair on browsers's localStorage
 */
export const setItemIntoLocalStorage = (item, value) =>
  window.localStorage.setItem(item, value);

/**
 * Retrieve a value from a key stored on browsers's localStorage
 */
export const getItemFromLocalStorage = (item) =>
  window.localStorage.getItem(item);
