import React from "react";
import PropTypes from "prop-types";

import { tabletLandscape } from "../../contants/breakpoints";

import "./BoxLink.css";

const BoxLink = ({ children, colors, title, translationX, windowWidth }) => {
  return (
    <div
      className="box-link__wrapper"
      style={{
        animation:
          windowWidth >= tabletLandscape &&
          translationX !== 0 &&
          "hideboxlink 1.5s",
        transform: `translateX(${translationX}%)`,
      }}
    >
      {/* Left box */}
      <div
        className="box-link box-link__left"
        style={{ backgroundColor: colors.color1 }}
      >
        {title}
      </div>

      {/* Right box */}
      <div
        className="box-link box-link__right"
        style={{ backgroundColor: colors.color2 }}
      >
        {children}
      </div>
    </div>
  );
};

BoxLink.propTypes = {
  children: PropTypes.node.isRequired,
  colors: PropTypes.shape({
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  translationX: PropTypes.number,
  windowWidth: PropTypes.number,
};

BoxLink.defaultProps = {
  translationX: 0,
  windowWidth: 0,
};

export default BoxLink;
